import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ColorList from "../components/ColorList";
import { OrderContext } from "../components/providers/OrderProvider";
import calculateTotalWithDiscounts from "../utils/functions/calculateTotalWithDiscounts";

const Masks = () => {
  const { order, setOrder } = useContext(OrderContext);

  const navigate = useNavigate();

  const hasBasketProducts = order.subTotal > 0;
  const [isLoading, setIsLoading] = useState(true);

  const [colorList, setColorList] = useState(() => {
    var Airtable = require("airtable");
    var base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_SECRET_API_TOKEN_READ,
    }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

    const list = [];
    let unitPrice = 0;
    let category = "";

    base("Produkty")
      .select({
        maxRecords: 100,
        view: "Masky",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            unitPrice = record.get("Cena");
            category = record.get("Kategorie");
            const minSize = record.get("Velikost (ml)");
            let sizes = [];
            if(minSize){
              sizes = [{
                value: minSize,
                price_multiplicator: 1,
              }]
            } 
            list.push({
              id: record.id,
              name: record.get("Název"),
              price: unitPrice,
              productType: category,
              image: record.get("Fotka")[0]["thumbnails"]["large"]["url"],
              sizes: sizes,
              slug: record.get("Slug"),
            });
          });

          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }

          // Update basket prices and images
          let newBasket = order.basket;
          order.basket.forEach((b, i) => {
            if (
              b.productType === "Krycí barvy" ||
              b.productType === "Odstíny"
            ) {
              let newPrice = b.price;
              newBasket[i].price = newPrice;
            }
            const productIdx = list.findIndex((x) => x.slug === b.slug);
            if (productIdx > -1) {
              newBasket[i].image = list[productIdx].image;
            }
          });

          // Calculate new subtotal with discounts
          const newSubTotal = calculateTotalWithDiscounts(newBasket);

          setOrder({ ...order, basket: newBasket, subTotal: newSubTotal });
          localStorage.setItem(
            "order",
            JSON.stringify({
              ...order,
              basket: newBasket,
              subTotal: newSubTotal,
            })
          );

          setColorList(list);
          setIsLoading(false);
        }
      );
  });

  const onButton1Click = useCallback(() => {
    navigate("/delivery-choice");
  }, [navigate]);

  return (
    <>
      {isLoading && (
        <div className="min-h-[20rem] w-full flex items-center justify-center">
          <span className="w-[3rem] h-[3rem] border-solid rounded-full border-[0.25rem] border-color-black border-r-transparent animate-spin"></span>
        </div>
      )}
      {!isLoading && (
        <>
          <ColorList colorList={colorList} />
          {hasBasketProducts && (
            <button
              className="w-fit mx-auto hover:bg-gray cursor-pointer [border:none] py-[1.19rem] px-[2.5rem] bg-color-black rounded-full overflow-hidden flex flex-row items-center justify-center"
              onClick={onButton1Click}
            >
              <b className="relative text-[1rem] font-graphie text-color-white text-center">
                Pokračovat
              </b>
            </button>
          )}
        </>
      )}
    </>
  );
};

export default Masks;
