import { motion } from "framer-motion";
import { useCallback, useContext, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "../../svg/ArrowLeft";
import formatPrice from "../../utils/functions/formatPrice";
import { OrderContext } from "../providers/OrderProvider";
import CounterInput from "../shared/CounterInput";
import ProductSizeSelector from "../shared/ProductSizeSelector";
import { LockBodyScroll } from "./ShoppingCart";
import calculateTotalWithDiscounts from "../../utils/functions/calculateTotalWithDiscounts";

const dropIn = {
  hidden: {
    x: "100vh",
    opacity: 0.8,
  },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.25,
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    x: "100vh",
    opacity: 0,
  },
};

const MobileCart = ({ changeUnitCount, removeFromBasket, removeAllUnits }) => {
  const { setOpenShoppingCart, order } = useContext(OrderContext);

  const navigate = useNavigate();

  const onCompleteCart = useCallback(() => {
    if (order.subTotal > 0) {
      navigate("/payment-choice");
      setOpenShoppingCart(false);
    }
  }, [navigate]);

  const totalBeforeDiscount = useMemo(() => {
    if (!order?.basket) return 0;
    return order.basket.reduce((total, item) => {
      const originalPrice = item.sizes.find(
        size => size.value === item.selectedSize
      )?.price || item.price;
      return total + (originalPrice * item.units);
    }, 0);
  }, [order?.basket, order.subTotal]);

  const showTotalDiscountedPrice = useMemo(() => {
    if (!order?.basket) return false;
    
    // Group items by size and type
    const groupedItems = {};
    order.basket.forEach((item) => {
      const key = `${item.productType}_${item.selectedSize}`;
      if (!groupedItems[key]) {
        groupedItems[key] = {
          totalUnits: 0,
          totalPrice: 0
        };
      }
      groupedItems[key].totalUnits += item.units;
      groupedItems[key].totalPrice += item.price * item.units;
    });

    // Check if any group has 2 or more items (eligible for discount)
    const hasDiscountEligibleGroup = Object.values(groupedItems).some(
      group => group.totalUnits >= 2
    );

    // Only show if there's an actual price difference
    return hasDiscountEligibleGroup && totalBeforeDiscount > order.subTotal;
  }, [order?.basket, order.subTotal, totalBeforeDiscount]);

  return (
    <div className="fixed z-50 top-0 left-0 overflow-hidden h-screen w-screen">
      <LockBodyScroll />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        onClick={() => setOpenShoppingCart(false)}
        className="w-full h-full absolute top-0 left-0 bg-[#00152480]"
      />
      <motion.div
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="absolute flex flex-col justify-between z-10 top-0 right-0 bg-white w-full h-full"
      >
        <div>
          <div className="pt-10 px-3">
            <button
              className="w-12 h-12 bg-white outline-none cursor-pointer"
              onClick={() => setOpenShoppingCart(false)}
            >
              <ArrowLeft className="stroke-color-black w-6" strokeWidth={2} />
            </button>
          </div>
          <div>
            <div className="py-4 px-6 flex items-center justify-between gap-2">
              <div className="font-ivy-mode font-bold text-5xl leading-9 text-color-black">
                Košík
              </div>
              {order && order.basket && order.basket.length ? (
                <button
                  onClick={removeAllUnits}
                  className="outline-none bg-white cursor-pointer font-graphie text-base leading-6 font-semibold text-color-grey-600 flex items-center"
                >
                  <img src="/remove.svg" alt="" /> <span>Vyprázdnit</span>
                </button>
              ) : (
                <></>
              )}
            </div>
            {!order || !order.basket || !order.basket.length ? (
              <div className="px-6 mt-6 self-start">
                <p className="my-0 font-graphie font-semibold text-base leading-6 text-color-black">
                  Zatím v košíku nemáš žádné produkty.
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="px-6 py-4 flex flex-col gap-3 max-h-[calc(100vh-24rem)] overflow-auto">
            {order &&
              order.basket &&
              order.basket.map((basketItem, index) => (
                <ProductCard
                  key={index}
                  unit={basketItem}
                  onRemoveOrder={() => removeFromBasket(basketItem)}
                  onChangeUnitsCount={(value) =>
                    changeUnitCount(basketItem, value)
                  }
                  closeShoppingCart={() => setOpenShoppingCart(false)}
                />
              ))}
          </div>
        </div>
        <div>
          {order && order.basket && order.basket.length ? (
            <div className="px-6 pt-2 pb-4 bg-color-grey-100">
              <div className="flex justify-between gap-2">
                <div className="font-graphie font-semibold font-base leading-5 text-color-black">
                  Celkem:
                </div>
                <div className="flex flex-col items-end">
                  <div className="font-graphie font-bold text-base leading-5 text-color-black">
                    {formatPrice(order.subTotal || 0)} Kč
                  </div>
                  {showTotalDiscountedPrice && (
                    <div className="text-sm text-color-grey-600 line-through">
                      {formatPrice(totalBeforeDiscount)} Kč
                    </div>
                  )}
                </div>
              </div>
              <button
                onClick={onCompleteCart}
                className="mt-4 w-full cursor-pointer [border:none] py-2.5 bg-color-black rounded-full overflow-hidden flex flex-row items-center justify-center"
              >
                <b className="relative text-[1rem] leading-5 font-graphie text-color-white text-center">
                  Objednat
                </b>
              </button>
            </div>
          ) : (
            <></>
          )}
          <div className="pb-10 pt-4 px-6">
            <button
              onClick={() => setOpenShoppingCart(false)}
              className="w-full cursor-pointer [border:none] py-2.5 rounded-full overflow-hidden flex flex-row items-center justify-center bg-color-beige hover:bg-blanchedalmond"
            >
              <b className="relative text-[1rem] leading-5 font-graphie text-center text-color-black">
                Pokračovat v nákupu
              </b>
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const ProductCard = ({
  unit,
  onRemoveOrder,
  onChangeUnitsCount,
  closeShoppingCart,
}) => {
  const { order } = useContext(OrderContext);

  const selectedSizeObj = useMemo(() => {
    return unit.sizes.find((x) => x.value === unit.selectedSize);
  }, [unit, unit.selectedSize]);

  return (
    <div className="relative flex flex-col justify-between gap-2 pb-4 border-b border-solid border-color-grey-300">
      <div className="flex gap-4">
        <div className="rounded-full w-12 h-12 overflow-hidden shrink-0 flex flex-row items-start justify-start duration-150">
          {unit.id ? (
            <Link
              to={`/product/${unit.slug}`}
              onClick={closeShoppingCart}
              className="self-stretch flex-1 relative bg-darkslategray overflow-hidden"
            >
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src={unit.image}
              />
            </Link>
          ) : (
            <div className="self-stretch flex-1 relative bg-darkslategray overflow-hidden">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src={unit.image}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col w-full">
          <div className="mx-0 font-graphie font-semibold text-base leading-6 text-color-black">
            {unit.name}
          </div>
          <div className="flex justify-between items-center flex-wrap gap-3 mt-1.5">
            <div className="flex">
              <ProductSizeSelector
                sizes={unit.sizes}
                selectedSize={selectedSizeObj}
                containerClassName="gap-2"
                buttonClassName={
                  unit.sizes && unit.sizes.length > 1
                    ? "h-[2.188rem] text-[0.75rem]"
                    : "h-[2.188rem] text-[0.75rem] min-w-fit px-0"
                }
              />
            </div>
            <button
              className="outline-none bg-white cursor-pointer"
              onClick={onRemoveOrder}
            >
              <img src="/remove.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
      <CounterInput
        onIncrement={() => onChangeUnitsCount(1)}
        onDecrement={() => onChangeUnitsCount(-1)}
        value={unit.units}
      />
    </div>
  );
};
export default MobileCart;
