import { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import "../components/providers/OrderProvider";
import PaymentCard from "../components/payment/PaymentCard";
import { OrderContext } from "../components/providers/OrderProvider";
import calculateTotalWithDiscounts from "../utils/functions/calculateTotalWithDiscounts";

const PaymentChoice = ({ step }) => {
  const navigate = useNavigate();
  const { order, setOrder } = useContext(OrderContext);
  const hasBasketProducts = order.basket.length > 0;

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onSelectPaymentMethod = useCallback((method) => {
    localStorage.setItem(
      "order",
      JSON.stringify({
        ...order,
        paymentMethod: method,
      })
    );
    setOrder((order) => ({
      ...order,
      paymentMethod: method,
    }));
    navigate("/delivery-choice");
  }, []);

  useEffect(() => {
    if (!order || !hasBasketProducts) {
      navigate("/");
      return;
    }
    
    // Recalculate totals with discounts
    const newSubTotal = calculateTotalWithDiscounts(order.basket);
    if (newSubTotal !== order.subTotal) {
      const updatedOrder = {
        ...order,
        subTotal: newSubTotal
      };
      localStorage.setItem("order", JSON.stringify(updatedOrder));
      setOrder(updatedOrder);
    }
  }, [order.basket]);

  return (
    <div className="relative bg-color-white w-full overflow-hidden flex flex-col items-center justify-start">
      <Navbar onLogoClick={onLogoClick} step={step} />
      <main className="self-stretch flex flex-col pt-[2rem] px-[2.5rem] pb-[2.5rem] items-center justify-start gap-[2.5rem] text-center text-[1rem] text-color-black font-graphie sm:pt-[1.5rem] sm:pl-[1.5rem] sm:pr-[1.5rem] sm:pb-[2rem] sm:box-border">
        <header className="w-full self-stretch bg-color-white flex flex-col items-center justify-start gap-[1.5rem] max-w-[40rem] my-0 mx-auto text-center text-[1.5rem] text-color-black font-ivy-mode sm:gap-[1rem]">
          <h1 className="m-0 self-stretch relative text-[inherit] leading-[150%] font-bold font-inherit md:text-[1.5rem] sm:text-[1.5rem] sm:text-color-black">
            Jak chceš zaplatit?
          </h1>
          <div className="relative text-[1.25rem] leading-[150%] font-graphie text-dimgray md:text-[1.13rem]">
            Zvol způsob platby
          </div>
        </header>
        <div className="ml-0 pl-0 w-full grid grid-cols-[16.625rem_16.625rem] md:grid-cols-[1fr] gap-6 justify-center list-none">
          <PaymentCard
            name="Apple pay"
            iconSrc="/apple-pay.svg"
            onSelectPaymentMethod={()=>onSelectPaymentMethod("stripe")}
          />
          <PaymentCard
            name="Google Pay"
            iconSrc="/google-pay.svg"
            onSelectPaymentMethod={()=>onSelectPaymentMethod("stripe")}
          />
          <PaymentCard
            name="Kartou online"
            iconSrc="/card-pay.svg"
            onSelectPaymentMethod={()=>onSelectPaymentMethod("stripe")}
          />
          <PaymentCard
            name="Bankovní převod"
            iconSrc="/bank-transfer-pay.svg"
            onSelectPaymentMethod={()=>onSelectPaymentMethod("bankTransfer")}
          />
        </div>
      </main>
    </div>
  );
};

export default PaymentChoice;
