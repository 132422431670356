const calculateDiscountedPrice = (units, pricePerUnit) => {
  // Calculate how many items should be free (half of the total, rounded down)
  const freeItems = Math.floor(units / 2);
  // Calculate how many items should be paid for
  const paidItems = units - freeItems;
  // Return total price
  return paidItems * pricePerUnit;
};

export default calculateDiscountedPrice;
